import { Http } from '@zhfe/common-library'
import { Subject } from 'rxjs'
import { Injectable } from '@tanbo/vue-di-plugin'

export interface PaySettingRequestParams {
  /** 套餐组id列表 */
  group_ids: string[]
  /** 是否为签约套餐场景(true:则返回包含签约的套餐组 false:则返回不含签约的套餐组) */
  is_continuous_scene: boolean
}

export interface Plans {
  /** 支付平台(wx, ali) */
  pay_platform: string
  /** 签约模板id */
  plan_id: string
}

export interface ContinuousConfig {
  /** 签约模板 */
  plans: Plans[]
}

export interface PageDetail {
  /** 底部文案 */
  bottom?: string
  /** 底部价格文案样式 0:不展示 1:展示文字 2: 文字加删除线 */
  bottom_type?: number
  /** 现价(元) */
  current_fee?: number
  /** 折扣文案 */
  discount_desc?: string
  /** 优惠多少钱(元) */
  discount_fee?: number
  /** 是否终身的套餐展示 */
  is_diamond?: boolean
  /** 原价(元) */
  origin_fee?: number
  /** selected_type: 0 # 选中套餐后右下角的显示，0:不显示 1: 签约勾选套餐 2: 钻石/超级会员描述 */
  selected_type?: number
  /** # 角标文字(title_type=1) */
  title?: string
  /** 套餐倒计时结束时间戳，用于倒计时，没有则为0(title_type=3) */
  title_end_time?: number
  /** 角标图片(title_type=2) */
  title_img?: string
  /** 套餐倒计时开始时间戳，用于倒计时，没有则为0(title_type=3) */
  title_start_time?: number
  /** 角标展示形式 0:不展示 1:展示文字 2:展示图片 3:展示倒计时 */
  title_type?: number
  /** 服务于超级会员页，用于展示中间价格旁边的带划线的价格 */
  center_fee?: number
  /** 套餐下方描述 */
  outer_desc?: string
}

export interface PayPackages {
  /** 套餐id */
  product_id: string
  /** 是否为签约套餐场景(true:则返回包含签约的套餐组 false:则返回不含签约的套餐组) */
  is_continuous: boolean
  /** PayPackageContinuous 签约套餐的配置 */
  continuous_config?: ContinuousConfig
  /** 页面展示信息(透传apollo配置), 具体定义见apix备注 */
  page_detail?: PageDetail
}

export interface PayGroups {
  /** 支付套餐组id */
  group_id: string
  /** 默认选中套餐id */
  default_product_id: string
  /** 支付套餐 */
  pay_packages: PayPackages[]
}

export interface PaySettingResponseData {
  /** 支付套餐组列表 */
  pay_groups: PayGroups[]
  /** 服务端时间戳 */
  server_timestamp_ms: string
}

@Injectable()
export class PaySettingsService {
  onPaySettingToggle = new Subject<PayPackages>() /** 当前选中套餐主题 */
  updatePayInfo = new Subject()

  constructor(private http: Http) {

  }

  /**
   * @description 解析时分秒
   */
  analysisTime(expiresTime: number) {
    const dealNum = num => (num >= 10 ? num : `0${num}`)
    const h = dealNum(Math.floor(expiresTime / 3600))
    const m = dealNum(Math.floor((expiresTime - h * 3600) / 60))
    const s = dealNum(Math.floor(expiresTime - h * 3600 - m * 60))

    return { h, m, s }
  }

  /** 获取支付套餐 */
  requestPaySetting (params: PaySettingRequestParams) {
    return this.http.post<PaySettingResponseData>('/paypackagebase/list_group', params)
  }
}
