
import { defineComponent, onBeforeUnmount, onMounted, reactive } from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import QrcodeVue from 'qrcode.vue'
import DragHeader from '@/components/drag-header/drag-header.vue'
import { UserService } from '../../services/user.service'
import { BridgeService } from '../../services/bridge.service'
import { ConfigService } from '@/services/config.service';
import { CouponService } from './coupon.service'
import { CookieService } from '@/services/cookie.service'

export default defineComponent({
  name: 'Coupon',
  components: {
    QrcodeVue,
    DragHeader
  },
  emits: ['close', 'paysucc', 'login'],
  setup(_, context) {
    const injector = useReflectiveInjector([CouponService])
    const user = injector.get(UserService)
    const bridge = injector.get(BridgeService)
    const cookie = injector.get(CookieService)
    const config = injector.get(ConfigService as Type<ConfigService>)
    const couponService = injector.get(CouponService)

    const viewModel = reactive({
      isLogin: user.isLogin, /** 是否登录 */
      isPayGuide: false, /** 控制支付引导显示 */
      groupIds: [config.getPackageId], /** 默认套餐id数组 */
      isExpires: false,
      price: 0,
      qrText: '',
      orderId: null,
      discount: 0,
      loopPayResultTimer: null,
    })

    function showPayGuide () {
      viewModel.isPayGuide = !viewModel.isPayGuide
    }

    /**
     * @description 处理成功的普通二维码
     */
    function handleNormalQrcodeSuccess (url:string, orderId: string) {
      viewModel.isExpires = false
      viewModel.qrText = url
      viewModel.orderId = orderId

      payOrderLoopAction()
    }

    /**
     * @description 订单轮询操作
     */
    function payOrderLoopAction () {
      clearLoopOrderTimer()

      let totalTime = 3600 // 轮询时长为1小时
      const time = 3 // 每隔3秒轮询一次

      viewModel.loopPayResultTimer = setInterval(() => {
        totalTime = totalTime - time

        if (totalTime > 0) {
          const params = {
            order_id: viewModel.orderId,
            payapp: cookie.productId
          }

          couponService.requestPayResult(params).then(res => {
            if (res.order.status === 'paid') {
              handlePaySuccess()
            }
          })
        } else {
          clearLoopOrderTimer()
          viewModel.isExpires = true
        }
      }, time * 1000)
    }

    /**
     * @description 处理支付成功
     */
    async function handlePaySuccess () {
      clearLoopOrderTimer()
      bridge.refreshUserInfo() // 通知客户端刷新用户信息，用于同步客户端用户状态
      await user.requestUserInfo() // 更新前端用户信息
      context.emit('paysucc')
    }

    /**
     * @description 清除轮询订单定时器标识
     */
    function clearLoopOrderTimer () {
      clearInterval(viewModel.loopPayResultTimer)
    }

    /**
     * @description 初始化套餐
     */
    async function initPaySetting() {
      const couponSettingRes = await couponService.requestCouponSetting({
        pay_group: config.getPackageId
      })

      const couponProductId = couponSettingRes.dialog.coupon_product_id
      viewModel.discount = couponSettingRes.dialog.show_detail.discount
      viewModel.price = couponSettingRes.dialog.show_detail.fee

      const payQrcodeRes = await couponService.requestWeChatPayQrcode({
        payapp: cookie.productId,
        product_id: couponProductId,
        client_extra: {
          ...couponService.client_extra
        }
      })

      handleNormalQrcodeSuccess(payQrcodeRes.wx_resp.code_url, payQrcodeRes.order_id)
    }

    onMounted(() => {
      initPaySetting()
      couponService.couponReport()
    })

    onBeforeUnmount(() => {
      clearLoopOrderTimer()
    })

    return {
      viewModel,
      showPayGuide,
      handleLogin () {
        bridge.userLogin()
        context.emit('login')
      },
      handleClose () {
        context.emit('close')
      },
    }
  }
})
