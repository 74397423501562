
import { CommonService } from '@/services/common.service';
import { UserService } from '@/services/user.service';
import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
import {defineComponent, reactive, onUnmounted, onMounted } from 'vue'
import { useRoute } from 'vue-router';

/** 卡点页支付状态 */
export default defineComponent({
  name: 'PayPointStatus',
  props: {
    status: {
      type: String,
      default: 'success'
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const viewModel = reactive({
      date: ''
    })
    const injector = useReflectiveInjector()
    const user = injector.get(UserService)
    const common = injector.get(CommonService)
    const route = useRoute()
    const isPage = route.name === 'PayPointStatus'

    const sub = user.onUserInfoChanged.subscribe(() => {
      vipDate()
    })

    onMounted(() => {
      vipDate()
    })

    onUnmounted(() => {
      sub.unsubscribe()
    })

    const config = {
      success: {
        title: '温馨提示',
      },
      fail: {
        title: '错误提示'
      }
    }

    function vipDate () {
      viewModel.date = user.getPriorityMemberInfo?.page_detail?.is_diamond ? '永久' : common.handleDate(user.memberExpiredMs)
    }

    function handleClose () {
      if (isPage) {
        window.close()
        return
      }

      emit('close')
    }

    return {
      title: config[props.status as string].title,
      state: props.status,
      isPage,
      viewModel,
      handleClose
    }
  }
})
