
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { UserService } from '../../../services/user.service'
import { UserInfoService } from '../../user-info/user-info.service'
import { PayPointService } from './index.service'
import Pay from './_components/pay.vue'
import Coupon from '@/components/coupon/coupon.vue'
import DragHeader from '@/components/drag-header/drag-header.vue'
import { CookieService } from '@/services/cookie.service'
import { ToastService } from '@/services/toast.service'
import { LocalStorageService } from '@/services/local-storage.service'

/**
 * 优惠券展示间隔时间： 1 天
 */
const COUPON_VISIBLE_LATE_TIME = 24 * 60 * 60 * 1000

/** 会员中心 */
export default defineComponent({
  name: 'Home',
  components: {
    Pay,
    Coupon,
    DragHeader,
  },
  setup() {
    const injector = useReflectiveInjector([PayPointService, UserInfoService])
    const service = injector.get(PayPointService)
    const userInfo = injector.get(UserInfoService)
    const user = injector.get(UserService)
    const cookie = injector.get(CookieService)
    const toast = injector.get(ToastService)
    const localStorageService = injector.get(LocalStorageService)

    const viewModel = reactive({
      isVip: user.isVip,
      isDiamondVIP: false,
      isLogin: user.isLogin,
      isRenew: false,
      isShowCoupon: false
    })

    /** 滚动节点 */
    const scrollNode = ref(null)

    /** 订阅续费状态变化 */
    userInfo.onRenew.subscribe(() => (viewModel.isRenew = true))

    /** 订阅用户信息变化 */
    user.onUserInfoChanged.subscribe(() => {
      viewModel.isVip = user.isVip
      viewModel.isDiamondVIP = user.isDiamondVIP
      viewModel.isRenew = false
      checkIsCouponLogin()
    })

    function showCouponDialog () {
      const now = Date.now()
      cookie.show_from = '21'
      localStorageService.couponShowTimestamp = (now + COUPON_VISIBLE_LATE_TIME).toString()
      viewModel.isShowCoupon = true
    }

    function checkIsCouponLogin () {
      const couponCacheTime = parseInt(localStorageService.couponShowTimestamp)
      const isVip = viewModel.isVip || viewModel.isDiamondVIP
      if (!isVip && couponCacheTime === -1) {
        showCouponDialog()
      }
    }

    onMounted(() => {
      service.report()
    })

    return {
      viewModel,
      scrollNode,
      handleClose () {
        // try {
        //   const isVip = viewModel.isVip || viewModel.isDiamondVIP
        //   const couponCacheTime = parseInt(localStorageService.couponShowTimestamp)
        //   const now = Date.now()
        //   if (!isVip && (!couponCacheTime || couponCacheTime < now)) {
        //     showCouponDialog()
        //   } else {
        //     window.close()
        //   }
        // } catch (error) {
        //   window.close()
        // }
        window.close()
      },
      handleCouponClose () {
        viewModel.isShowCoupon = false
      },
      handleCouponPaySuccess () {
        viewModel.isShowCoupon = false
        toast.showToast({ message: '支付成功' })
      },
      handleCouponLogin () {
        localStorageService.couponShowTimestamp = '-1'
      }
    }
  }
})
