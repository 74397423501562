<template>
  <div class="drag-header" />
</template>

<style lang="scss" scoped>
.drag-header {
  width: 100%;
  height: 32px;
  -webkit-app-region: drag;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
}
</style>
