import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'
import { CookieService } from '@/services/cookie.service';
import { BridgeService } from '@/services/bridge.service';
import { UserService } from '@/services/user.service';
import {ReportService} from '@/services/report.service';
import {ConfigService} from '@/services/config.service';

export interface PayWechatQrCodeRequestParams {
  /** 支付的APP，由服务端分配 */
  payapp: string
  /** 付费产品ID，预先配置好 */
  product_id: string
  /** 见备注 */
  client_extra?: any
}

export interface WxResp {
  /** 用于生成支付二维码，然后提供给用户进行扫码支付。 */
  code_url: string
}

export interface PayWechatQrCodeResponseData {
  /** 预订单ID，全局唯一，以后查询也用这个ID */
  order_id: string
  /** 金额，单位分 */
  fee: number
  /** 过期时间，客户端只能在这个时间之前支付 */
  expires: string
  /** 微信调起支付需要的参数 */
  wx_resp: WxResp
  /** 货币，默认CNY */
  fee_type?: string
}

export interface AliPayRequestParams {
  /** 支付的APP，由服务端分配 */
  payapp: string
  /** 付费产品ID，预先配置好 */
  product_id: string
  /** 手机网页支付：支付宝处理完请求后，当前页面自动跳转到商户网站里指定页面的http路径。 */
  return_url: string
  /** 上报信息 */
  client_extra?: any
}

export interface AliResp {
  /** 用于生成支付二维码，然后提供给用户进行扫码支付。 */
  code_url: string
}

export interface AliPayResponseData {
  /** 预订单ID，全局唯一，以后查询也用这个ID */
  order_id: string
  /** 金额，单位分 */
  fee: number
  /** 过期时间，客户端只能在这个时间之前支付 */
  expires: string
  /** 支付宝调起支付需要的参数 */
  ali_resp: AliResp
  /** 货币，默认CNY */
  fee_type?: string
}

export interface PayResultRequestParams {
  /** 订单ID */
  order_id: string
  /** 支付app，服务端分配 */
  payapp: string
}

export interface PayResultOrder {
  /** 支付平台：wx, ali, apple */
  platform: string
  /** 支付方式：app, contract, h5, qrcode, iap, ... */
  pay_type: string
  order_id: string
  /** 金额，单位分 */
  fee: number
  create_time: string
  /** 状态：paying-支付中；paid-已支付；refunding-退款中；refund-已退款；fail-支付失败 */
  status: string
  /** 产品ID */
  product_id: string
  /** 产品信息 */
  // product_info: ProductInfo
  /** 关联的合约ID */
  contract_id?: string
  /** 货币，默认CNY */
  fee_type?: string
  pay_time?: string
  refund_time?: string
}

export interface PayResultResponseData {
  order: PayResultOrder
}

export interface TurnShortLinkRequestParams {
  /** 要缩短的URL */
  url: string
  /** 场景(login, pay)  监控统计数据使用 */
  scenes?: string
}

export interface turnShortLinkResponseData {
  /** 被缩短的url */
  short_url: string
}

export interface PaySettingRequestParams {
  /** 套餐组id列表 */
  group_ids: string[]
  /** 是否为签约套餐场景(true:则返回包含签约的套餐组 false:则返回不含签约的套餐组) */
  is_continuous_scene: boolean
}

export interface Plans {
  /** 支付平台(wx, ali) */
  pay_platform: string
  /** 签约模板id */
  plan_id: string
}

export interface ContinuousConfig {
  /** 签约模板 */
  plans: Plans[]
}

export interface PageDetail {
  /** 底部文案 */
  bottom?: string
  /** 底部价格文案样式 0:不展示 1:展示文字 2: 文字加删除线 */
  bottom_type?: number
  /** 现价(元) */
  current_fee?: number
  /** 折扣文案 */
  discount_desc?: string
  /** 优惠多少钱(元) */
  discount_fee?: number
  /** 是否终身的套餐展示 */
  is_diamond?: boolean
  /** 原价(元) */
  origin_fee?: number
  /** selected_type: 0 # 选中套餐后右下角的显示，0:不显示 1: 签约勾选套餐 2: 钻石/超级会员描述 */
  selected_type?: number
  /** # 角标文字(title_type=1) */
  title?: string
  /** 套餐倒计时结束时间戳，用于倒计时，没有则为0(title_type=3) */
  title_end_time?: number
  /** 角标图片(title_type=2) */
  title_img?: string
  /** 套餐倒计时开始时间戳，用于倒计时，没有则为0(title_type=3) */
  title_start_time?: number
  /** 角标展示形式 0:不展示 1:展示文字 2:展示图片 3:展示倒计时 */
  title_type?: number
  /** 服务于超级会员页，用于展示中间价格旁边的带划线的价格 */
  center_fee?: number
  /** 套餐下方描述 */
  outer_desc?: string
}

export interface PayPackages {
  /** 套餐id */
  product_id: string
  /** 是否为签约套餐场景(true:则返回包含签约的套餐组 false:则返回不含签约的套餐组) */
  is_continuous: boolean
  /** PayPackageContinuous 签约套餐的配置 */
  continuous_config?: ContinuousConfig
  /** 页面展示信息(透传apollo配置), 具体定义见apix备注 */
  page_detail?: PageDetail
}

export interface PayGroups {
  /** 支付套餐组id */
  group_id: string
  /** 默认选中套餐id */
  default_product_id: string
  /** 支付套餐 */
  pay_packages: PayPackages[]
}

export interface PaySettingResponseData {
  /** 支付套餐组列表 */
  pay_groups: PayGroups[]
  /** 服务端时间戳 */
  server_timestamp_ms: string
}

@Injectable()
export class CouponService {
  /** 支付上报 */
  client_extra = {
    version: this.reporter.report_version, /** 埋点版本号 */
    vip_version: this.cookie.vipVersion, /** 会员客户端版本 */
    show_from: '21', /** 会员页展示来源 */
    fun_from: this.cookie.fun_from, /** 权益来源 */
    vip_status: this.user.isVip ? '1' : '2', /** 是否会员 1：是，2：否 */
    current_uuid: this.cookie.uuid, /** 产品的uuid */
    current_tryno: this.cookie.tryno, /** 产品的tryno */
    current_svrid: this.cookie.svrid, /** 产品的svrid */
    tid1: String(this.bridge.tidInfo.tid1), /** 主通道号（内通道号） */
    tid2: String(this.bridge.tidInfo.tid2), /** 副通道号（内通道号） */
    tod1: String(this.bridge.tidInfo.tod1), /** 主通道号（外通道号） */
    tod2: String(this.bridge.tidInfo.tod2), /** 副通道号（外通道号） */
  }

  constructor(
    private http: Http,
    private cookie: CookieService,
    private bridge: BridgeService,
    private user: UserService,
    private reporter: ReportService,
    private config: ConfigService
  ) {
  }

  couponReport = this.reporter.makeReporter('waitui_yasuo_couponpop:374 ver:short', {
    ver: 1
  })

  /**
   * @description 请求微信支付二维码
   */
  requestWeChatPayQrcode (params: PayWechatQrCodeRequestParams) {
    return this.http.post<PayWechatQrCodeResponseData>('/baoquvip/payv2/wx/native', params)
  }

  /**
   * @description 请求支付宝支付二维码
   */
  requestAliPayQrcode (params: AliPayRequestParams) {
    return this.http.post<AliPayResponseData>('/baoquvip/payv2/ali/native', params)
  }

  /**
   * @description 请求生成短链接
   */
  requestTurnShortLink (params: TurnShortLinkRequestParams) {
    return this.http.post<turnShortLinkResponseData>('/url/shortable', params)
  }

  /**
   * @description 请求支付结果
   */
  requestPayResult (params: PayResultRequestParams) {
    return this.http.post<PayResultResponseData>('/baoquvip/payv2/order/query', params)
  }

  /** 获取支付套餐 */
  requestPaySetting (params: PaySettingRequestParams) {
    return this.http.post<PaySettingResponseData>('/paypackagebase/list_group', params)
  }

  requestCouponSetting (params: { pay_group: string }) {
    return this.http.post<any>('/coupon/group_conf_abtesting', params)
  }
}
